<template>
  <div
    class="container p-4 is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered">
    <div class="title">Możesz tutaj uzyskać pliki .json z tłumaczeniami wrzucając wyeksportowany plik .csv</div>
    <label class="button-container is-relative">
      <add-icon class="add-icon mr-1"></add-icon>
      <div class="add-title has-text-secondary">UPLOAD .CSV WITH LOCALES</div>
      <input type="file" accept=".csv" @change="onNewCsvFile" style="visibility: hidden; width: 0; height: 0"/>
    </label>
    <div class="button" @click="generateCsvFile">GET .CSV WITH CURRENT TRANSLATIONS</div>
    <div v-if="error">
      {{ error | pluckError }}
    </div>
  </div>
</template>

<script>
import AddIcon from "@/assets/icons/add_2.svg";

const CsvParser = require("jquery-csv");

const ROW_INDEX_WITH_HEADERS = 6;

export default {
  name: "LocalizationParser",

  components: {AddIcon},

  data() {
    return {
      error: null,
    };
  },

  computed: {},

  methods: {
    onNewCsvFile(event) {
      const file = event.target.files && event.target.files[0];

      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = evt => {
        this.parseCsvToString(evt.target.result);
      };
    },

    parseCsvToString(csvString) {
      const arrays = CsvParser.toArrays(csvString);
      const strippedArrays = arrays.splice(ROW_INDEX_WITH_HEADERS, arrays.length - 1);
      const headers = strippedArrays.splice(0, 1)[0];

      if (headers.length > 2) {
        for (let headerIndex = 2; headerIndex < headers.length; headerIndex++) {
          const localeCode = this.getLocaleCodeFromHeader(headers[headerIndex]);
          setTimeout(() => {
            this.exportLocale(localeCode, headerIndex, strippedArrays);
          }, headerIndex * 150);
        }
      }
    },

    exportLocale(localeCode, headerIndex, locales) {
      let objectToStringify = {};

      for (let index = 0; index < locales.length; index++) {
        const row = locales[index];
        const groupId = row[0];
        const id = row[1];

        if (!(groupId in objectToStringify)) {
          objectToStringify[groupId] = {};
        }
        objectToStringify[groupId][id] = row[headerIndex];
      }
      const jsonString = JSON.stringify(objectToStringify);
      this.exportJsonString(jsonString, `${localeCode}.json`);
    },

    exportJsonString(jsonString, filename) {
      var blob = new Blob([jsonString], {type: "application/json;charset=utf-8;"});
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    generateCsvFile() {
    },

    getLocaleCodeFromHeader(header) {
      return header.substring(header.lastIndexOf("(") + 1, header.lastIndexOf(")"));
    },
  },
};
</script>

<style scoped lang="scss">
.button-container {
  height: 56px;
  width: 240px;
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  &:hover {
    border: 1px solid var(--primary-color);
  }

  &:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: var(--primary-color);
    opacity: 0.1;
  }
}

.add-icon {
  width: 22px;
  height: 22px;
}

.add-title {
  font-size: 18px;
  line-height: 130%;
}
</style>
